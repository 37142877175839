import { HttpClient } from "./http-client";
import { ENDPOINT } from "./end-point";

export default class ServiceApi extends HttpClient {
  constructor() {
    super({
      headers: {
        Authentication:
          "c3B2LTItUEFnOVRmWjg5UVZqWjVZSDhVWkZoc3RrRHBHbmNkSkNxUUI4R2NTc1NxbXVrYjM0R0RBQVVETDlDNDdGN0Y4bg==",
        accept: "text/json",
        responseType: "json",
        ".AspNetCore.Culture": "c=en|uic=en-US",
        "Content-Type": "application/json",
      },
      baseURL: `https://spv-api${(window as any).enviroment}.pervolare.net/api`,
    });
  }

  geolocation() {
    return this.post(ENDPOINT.geolocation, {});
  }

  quotation(body) {
    return this.post(ENDPOINT.quote, body);
  }

  getParametersByDomain(domain?: string) {
    const DOMAIN =
      domain ??
      localStorage.getItem("spv_topLevelDomain") ??
      window["env"].topLevelDomain;

    return this.get(`${ENDPOINT.parametersByDomain}?domain=${DOMAIN}`);
  }

  sendEmailQuote(id: number) {
    return this.post<boolean>(`${ENDPOINT.mailQuote}?entityId=${id}`, {});
  }
}
