import {
  HttpClientConfig,
  HttpClientHeaders,
  HttpClientOptions,
} from './client.types';

export class HttpClient {
  private _baseURL: string;
  private _headers: HttpClientHeaders;

  constructor(options?: HttpClientConfig) {
    this._baseURL = options?.baseURL || '';
    this._headers = options?.headers || {};
  }

  protected setHeader(key: string, value: string) {
    this._headers[key] = value;
    return this;
  }

  private async _fetchJSON<T = any>(
    endpoint: string,
    options?: HttpClientOptions
  ): Promise<T> {
    const res = await fetch(this._baseURL + endpoint, {
      ...options,
      headers: this._headers,
    });

    if (!res.ok) throw new Error(res.statusText);

    if (!options?.parseResponse && res.status !== 204)
      return (await res.json()).result;

    return undefined as any;
  }

  protected get<T = any>(endpoint: string, options?: HttpClientOptions) {
    return this._fetchJSON<T>(endpoint, {
      ...options,
      method: 'GET',
    });
  }

  protected post<T = any>(endpoint: string, body: any, options?: HttpClientOptions) {
    return this._fetchJSON<T>(endpoint, {
      ...options,
      body: JSON.stringify(body),
      method: 'POST',
    });
  }
}
